import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import qs from "qs";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import {
  OpenCorrectZorbiLink,
  useCanUseExtension,
  useDetectBrowser,
} from "../utils";
import { Formik, Field, Form } from "formik";
import { TrackingAPI } from "../utils/trackingapi";

import SEO from "../components/common/layout/seo";

const DeletePage = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const canUseExtension = useCanUseExtension();
  const browser = useDetectBrowser();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userId] = useState(queryParams.userId);

  useEffect(() => {
    if (userId) {
      TrackingAPI.trackUninstall(userId);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Navigation />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <div className="max-w-2xl mx-auto text-center">
            <div className="max-w-md mx-auto mb-8">
              <h2 className="mb-2 text-4xl font-bold font-heading">
                Privacy Policy
              </h2>
            </div>
            <div>
              <h3>
                Updated: <span class="p">September 2023</span>
              </h3>
              <p>
                <br />
              </p>
              <p>
                <a href="http://www.zorbiapp.com/" class="a" target="_blank">
                  This Privacy Policy describes Zorbi’s privacy practices in
                  relation to information that we collect through the website{" "}
                </a>
                www.zorbiapp.com operated by us from which you are accessing
                this Privacy Policy (the “Website”) and through the software
                applications made available by us for use on or through
                computers and mobile devices that link to this Privacy Policy
                (the “App”).
              </p>
              <p>
                <br />
              </p>
              <h4>
                To summarize our data privacy commitment in plain English:
              </h4>
              <ul id="l1">
                <li data-list-text="●">
                  <p>
                    We do not sell or share your data with third parties to
                    advertise or market their services or products to you.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    We use the data you share with Zorbi solely to provide you
                    the Zorbi service, to improve it, and to keep you updated
                    about new developments. And we only use third party services
                    that have made the same commitment to us.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      If you ever want to be forgotten, simply email us at
                      contact@zorbiapp.co
                    </a>
                    m and all your data will be completely removed from our
                    system.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">COPPA &amp; FERPA in the USA</h1>
                  <p>
                    <a
                      href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online"
                      class="a"
                      target="_blank"
                    >
                      This policy is in accordance with the U.S. Children’s
                      Online Privacy Protection Act (“COPPA”) and Family
                      Educational Rights and Privacy Act (FERPA), and outlines
                      our practices world-wide regarding the personal
                      information of all students under 13. For more information
                      about COPPA and general tips about protecting children’s
                      online privacy, please visit{" "}
                    </a>
                    <span>OnGuard Online</span>.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    The policies for childrens’ privacy protection outlined in
                    this Privacy Policy are
                  </p>
                  <p>
                    intended to supplement the information appearing in the
                    general Privacy Policy and apply to all students in
                    education, not only those under age 13. Where this COPPA
                    Privacy Policy conflicts with our general Privacy Policy
                    concerning information about a Child, the terms of this
                    COPPA Privacy Policy will prevail over our general Privacy
                    Policy.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>Teacher or schools consent in lieu of a parent</h2>
                  <p>
                    Zorbi collects limited personal information from students
                    where that student’s school, district, or teacher has
                    provided access to the Zorbi service.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    If you are accessing Zorbi on behalf of a Class, School or
                    District, anywhere in the world, the following provisions
                    also apply:
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    You represent and warrant that you are solely responsible
                    for complying with the Child Online Privacy Protection Act
                    (COPPA) or similar requirement to
                  </p>
                  <p>
                    secure parental consent for accessing applications which
                    require personal information from children under 13.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    With regard to school-based activities, COPPA allows
                    teachers and school administrators to act in the stead of
                    parents to provide consent for the collection of personal
                    information from children.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    <a
                      href="http://www.ftc.gov/privacy"
                      class="a"
                      target="_blank"
                    >
                      For more information on COPPA, please see{" "}
                    </a>
                    <span>www.ftc.gov/privacy</span>.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">Personal information stored</h1>
                  <p>
                    In order to create a unique account, Zorbi does require that
                    all users enter their email address and create a password,
                    which is stored on the site, or use their own Google or
                    Microsoft Account to sign on (Single-Sign-On), and may
                    collect additional
                  </p>
                  <p>
                    information such as name of school or class, and whether
                    they are a teacher or student, in order to assign the user
                    to the correct user group. Documents may be uploaded to
                    Zorbi by users in order to create flashcards. Zorbi does not
                    store these files once the flashcard creation process is
                    completed. Flashcards made by users are stored on the site
                    in order to display them to other users viewing a shared
                    deck, and to display them to the user if the same file is
                    later re-opened.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    We collect and use the following information to provide,
                    improve and protect our Services:
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Account – We collect, and associate with your account,
                    information you give us like your name, email address, phone
                    number, payment info, and physical address. Some of our
                    services let you access your accounts and your
                  </p>
                  <p>information with other service providers.</p>
                </li>
                <li data-list-text="●">
                  <p>
                    Services – When you use our Services, we store, process and
                    transmit your files and information related to them (for
                    example, the flashcards you make). If you give us access to
                    your contacts, we’ll store those contacts on our servers
                    only for you to use. This will make it easy for you to do
                    things like sharing your stuff, and inviting others to use
                    the Services.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Usage – We collect information from and about the devices
                    you use to access the Services, to aid trouble-shooting and
                    optimisation of the Services. This
                  </p>
                  <p>
                    includes things like IP addresses, the type of browser and
                    device you use, the web page you visited before coming to
                    our sites, and identifiers associated
                  </p>
                  <p>
                    with your devices. Your devices (depending on their
                    settings) may also transmit location information to the
                    Services.
                  </p>
                  <p>
                    Please note that users, including children, can choose
                    whether to share this
                  </p>
                  <p>
                    information with us, but certain features may not function
                    without it. As a result, users may not be able to access
                    certain features if required information has not been
                    provided. We will not require a user to provide more
                    information than is reasonably necessary in order to
                    participate in the online activity, and we use this personal
                    data for no other purpose than providing the service to the
                    user.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Zorbi collects limited personal information from students
                    only where that student’s school, district, or teacher has
                    engaged with Zorbi to collect personal information for the
                    use and benefit of the learning environment. This
                    information is not shared outside of the school or with any
                    third parties except those needed for the provision
                  </p>
                  <p>
                    of the service as outlined in this policy. Students of any
                    age cannot share their profiles publicly outside of their
                    classroom or school.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Teachers can annotate flashcard decks and provide comments
                    to students. Students can annotate deck, create and study
                    flashcards, submit assignments through your
                  </p>
                  <p>
                    learning management system, add comments and take online
                    tests; Teachers or students can record audio or video and
                    attach these recordings to content in our system. None of
                    this information is shared with any third parties without
                    the teacher or school’s consent.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Access to user information is given only as discussed below,
                    but we won’t give or sell it to advertisers or other
                    third-parties.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Others working for Zorbi – Zorbi uses certain trusted third
                    party services to help us provide, improve, protect, and
                    promote our Services. These third parties will access your
                    information only to perform tasks on our behalf and in
                    compliance with this Privacy Policy. They are listed below.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Other users you share it with – Our Services display
                    information like your name and email address to other users
                    in places like your user profile and sharing notifications.
                    Certain features let you make additional information
                    available to other users.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    You can also give third parties access to your information
                    and account – for example, via Zorbi APIs. Just remember
                    that their use of your information will be governed by their
                    privacy policies and terms.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Law &amp; Order – We may disclose your information to third
                    parties if we determine that such disclosure is reasonably
                    necessary to (a) comply with the law; (b) protect any person
                    from death or serious bodily injury; (c) prevent fraud or
                    abuse of Zorbi or our users; or (d) protect Zorbi’s property
                    rights.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Stewardship of your data is critical to us and a
                    responsibility that we embrace. We believe that our users’
                    data should receive the same legal protections regardless of
                    whether it’s stored on our services or on their home
                    computer’s hard drive. We’ll abide by our principles when
                    receiving, scrutinizing and responding to government
                    requests for our users’ data:
                  </p>
                  <ul id="l2">
                    <li data-list-text="○">
                      <p>Be transparent,</p>
                    </li>
                    <li data-list-text="○">
                      <p>Fight blanket requests,</p>
                    </li>
                    <li data-list-text="○">
                      <p>Protect all users, and</p>
                    </li>
                    <li data-list-text="○">
                      <p>Provide trusted services.</p>
                      <p>
                        <br />
                      </p>
                      <h1 className="mt-2 mb-4">
                        Review and Deletion of Personal Information
                      </h1>
                      <h2>
                        General Rules for Deletion of Personal Information
                      </h2>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If you cease to agree with Zorbi’s terms of use and
                          privacy policy at some point in the future, you may
                          opt-out and have your personally identifiable
                          information stored by Zorbi removed from our system by
                          contacting us directly at{" "}
                        </a>
                        contact@zorbiapp.com. We will respond to your request
                        within 30 days.
                      </p>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If you wish to review your personally identifiable
                          information stored by Zorbi, you may email us your
                          request at{" "}
                        </a>
                        contact@zorbiapp.com . We will respond to your request
                        within 30 days.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If your personally identifiable information changes,
                          or if you no longer desire our service, you may
                          correct, update, or delete it by making the change
                          directly in the user profile in the Zorbi App, or
                          email us at{" "}
                        </a>
                        contact@zorbiapp.com . We will respond to your request
                        within 30 days.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        We will retain your information only for as long as your
                        account is active or as needed to provide you services.
                        If you wish to cancel your account or request that we no
                        longer use your information to provide you services,
                        contact us at
                      </p>
                      <p>
                        support@zorbiapp.com. We will only retain and use your
                        information as necessary to comply with our legal
                        obligations.
                      </p>
                      <p>
                        <br />
                      </p>
                      <h2>
                        Rules for Deletion of Personal Information for children
                      </h2>
                      <p>
                        Under FERPA regulations, Parents have the right to
                        refuse the site further contact with their child and to
                        have access to their child’s school record information
                        and to have it deleted by contacting the school
                        administrator.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        If you are a parent and would like more information on
                        parental rights with respect to a child’s educational
                        record under the U.S. Family Educational Rights and
                        Privacy
                      </p>
                      <p>
                        <a
                          href="http://www.ed.gov/policy/gen/guid/fpco/ferpa/index.html"
                          class="a"
                          target="_blank"
                        >
                          Act (FERPA), please visit the{" "}
                        </a>
                        <a
                          href="http://www.ed.gov/policy/gen/guid/fpco/ferpa/index.html"
                          class="s1"
                          target="_blank"
                        >
                          FERPA{" "}
                        </a>
                        <span>site</span>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          . If you believe that a student’s school, district, or
                          teacher has not required parental consent prior to our
                          collection of any personal information, contact us at{" "}
                        </a>
                        <a href="mailto:contact@zorbiapp.com" target="_blank">
                          contact@zorbiapp.com
                        </a>
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If you are the parent and guardian of a student using
                          Zorbi, and cease to agree with Zorbi’s terms of use
                          and privacy policy at some point in the future, you
                          may opt-out by contacting the Zorbi subscriber
                          (typically your school) if you are a school account
                          user, or contact us directly at{" "}
                        </a>
                        contact@zorbiapp.com if you are an individual account
                        user.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If you wish to review your or your student’s or your
                          child’s personally identifiable information stored by
                          Zorbi, you may email us your request at{" "}
                        </a>
                        contact@zorbiapp.com . We will respond to your request
                        within 30 days.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <a
                          href="mailto:contact@zorbiapp.com"
                          class="a"
                          target="_blank"
                        >
                          If your or your student’s or your child’s personally
                          identifiable information changes, or if you no longer
                          desire our service, you may correct, update, or delete
                          it by making the change directly in the user profile
                          in the Zorbi App, or email us at{" "}
                        </a>
                        contact@zorbiapp.com . We will respond to your request
                        within 30 days.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        In any correspondence such as e-mail or mail, please
                        include the child’s username, the school or
                        organization, and the teacher or parent’s email address
                        and telephone number. To protect children’s privacy and
                        security, we will take reasonable steps to
                      </p>
                      <p>
                        help verify a teacher or parent’s identity before
                        granting access to any personal information.
                      </p>
                      <p>
                        <br />
                      </p>
                      <h1 className="mt-2 mb-4">
                        Is the Personal Information Secure?
                      </h1>
                      <p>
                        Your account is protected by a password for your privacy
                        and security. You must prevent unauthorized access to
                        your account and Personal Information by selecting and
                        protecting your password appropriately and limiting
                        access to your computer or device and browser by signing
                        off after you have finished accessing your account.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        We endeavor to protect the privacy of your account and
                        other Personal Information we hold in our records, but
                        we cannot guarantee complete security. The transmission
                        of information via the Internet is not completely
                        secure. Unauthorized entry or use, hardware or software
                        failure, and other factors, may compromise the security
                        of user information at any time.
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        When you enter your information on our site we encrypt
                        the transmission of that information using transport
                        layer security (TLS). Firewalls are used to segregate
                        application tiers and provide strict controls on access
                        to resources within our networks. Your data is also
                        encrypted at rest on the servers hosted by our cloud
                      </p>
                      <p>
                        services partners AWS and Google Cloud Platform, which
                        we selected because of their compliance with COPPA, and
                        their stringent security measures, including compliance
                        with the following certifications and third-party
                        attestations:
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ul>
                </li>
                <li data-list-text="●">
                  <p>SAS70 Type II audits</p>
                  <p>
                    Level 1 service provider under the Payment Card Industry
                    (PCI) Data Security Standard (DSS)
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>ISO 27001 certification</p>
                </li>
              </ul>
              <p>
                U.S. General Services Administration FISMA-Moderate level
                operation authorization
              </p>
              <p>
                <br />
              </p>
              <p>
                Outages, attacks, unauthorized use, or other factors may
                compromise the security of user information at any time. In the
                event of a security breach, we will notify you by posting on the
                Zorbi Website and notifying you by email using the email address
                on your account within 72 hours of Zorbi becoming aware of such
                breach. For EU residents, notification to the Zorbi Supervisory
                Authority shall be made within the same time period.
              </p>
              <p>
                <br />
              </p>
              <h1 className="mt-2 mb-4">Third Party Service Providers used</h1>
              <p>
                We work with a number of third-party service providers to help
                improve the quality of our service. We have contracts with these
                third parties which guarantee that they do not share your
                information with any other third parties and protect the data at
                least the same level as we do ourselves. In case a transfer of
                data is needed while collaborating with one of those third
                parties we make sure the transfer is executed to
              </p>
              <p>
                a country which is providing adequate protection according to
                EU. If this is not the case we sign agreements which includes
                the EU Standard Contractual Clauses (also called Model Clauses)
                published by the European Commission to protect EU data.
              </p>
              <p>
                <br />
              </p>
              <p>Zorbi uses the following third-party services :</p>
              <p>
                <br />
              </p>
              <ul id="l3">
                <li data-list-text="●">
                  <p>
                    <a
                      href="https://privacy.microsoft.com/en-ca/privacystatement"
                      class="a"
                      target="_blank"
                    >
                      Microsoft – if you choose to log into Zorbi using your
                      Microsoft account (often referred to as Single Sign-on or
                      “SSO”), your login will be authenticated by Microsoft. If
                      you open/save files on OneDrive, Microsoft will first
                      request authorization for this. You can review Microsoft’s
                      Privacy Policy for use of your Microsoft account{" "}
                    </a>
                    <span>here</span>
                    <a
                      href="https://support.microsoft.com/en-us/office/stop-sharing-onedrive-or-sharepoint-files-or-folders-or-change-permissions-0a36470f-d7fe-40a0-bd74-0ac6c1e13323"
                      class="a"
                      target="_blank"
                    >
                      ; and update or revoke your permissions as described{" "}
                    </a>
                    <span>here</span>.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Google – if you choose to log into Zorbi using your Google
                    account, your login will be authenticated by Google. If you
                    open/save files on Google Drive, Google will first request
                    authorization for this. You can review Google’s
                  </p>
                  <p>
                    <a
                      href="https://policies.google.com/privacy?hl=en-US"
                      class="a"
                      target="_blank"
                    >
                      Privacy Policy for use of your Google account{" "}
                    </a>
                    <span>here</span>
                    <a
                      href="https://myaccount.google.com/permissions"
                      class="a"
                      target="_blank"
                    >
                      ; and update or revoke your Google Permissions{" "}
                    </a>
                    <span>here</span>.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Google Analytics &amp; Tag Manager – an analytics service
                    used to help analyze your use of our Website and allow us to
                    improve our Service and provide you information on the
                    Service. Our agreement with Google complies with the terms
                    of this policy.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Stripe – when you upgrade your account online using a credit
                    card, your payment is securely processed using Stripe’s
                    e-payments service. Our contractual agreement with Stripe
                    complies with the terms of this policy.
                  </p>
                  <p>
                    We will only contract with future service providers that are
                    consistent with this policy or allow users a choice to send
                    information to the future provider.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      For more information on any of these third parties please
                      contact us at:{" "}
                    </a>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="s1"
                      target="_blank"
                    >
                      contact@zorbiapp.com
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">
                    European Union – GDPR Data Protection
                  </h1>
                  <p>
                    If you are using Zorbi in the European Union, The EU General
                    Data Protection Regulation (GDPR) applies to you and our
                    storage and processing of your data.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>Legal grounds, Rights of data subjects, Deletion</h2>
                  <p>
                    We process your data only on legal grounds, meaning when we
                    have your explicit consent; when we need this in order to
                    execute our contractual obligations towards you; when we are
                    obliged to do so under a legal act of any kind; or in order
                    to protect our legitimate interest.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>As a data subject you have rights to:</p>
                  <h2>Access</h2>
                  <p>
                    You may request access to your personal data to receive
                    information, for example, about the categories of personal
                    data that Zorbi is currently processing.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>Rectify</h2>
                  <p>
                    You may ask Zorbi to correct personal data that is
                    inaccurate or incomplete.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>Erase</h2>
                  <p>
                    You may ask Zorbi to erase personal data where one of the
                    following grounds applies:
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Where the personal data are no longer necessary in relation
                    to the purposes for which they were collected or otherwise
                    processed;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    You withdraw consent on which the processing is based and
                    where there is no other legal ground for the processing;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    You object to automated decision-making and there are no
                    overriding
                  </p>
                  <p>
                    legitimate grounds for the processing, or you object to the
                    processing for direct marketing;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>The personal data have been unlawfully processed;</p>
                </li>
                <li data-list-text="●">
                  <p>
                    The personal data have to be erased for compliance with
                    legal obligation in Union or Member State law to which Zorbi
                    is subject;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    The personal data have been collected in relation to the
                    offer of information society services.
                  </p>
                  <h2>Restrict</h2>
                  <p>
                    You may ask Zorbi to restrict how it processes your personal
                    data, requesting only their storage, where one of the
                    following grounds applies:
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    You contest the accuracy of your personal data, for a period
                    enabling Zorbi to verify the accuracy of your personal data;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    The processing is unlawful and you oppose the erasure of the
                    personal data and request the restriction of their use
                    instead;
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    Zorbi no longer needs the personal data for the purposes of
                    the processing, but they are required by you for the
                    establishment, exercise or defense of
                  </p>
                  <p>legal claims;</p>
                </li>
                <li data-list-text="●">
                  <p>
                    You have objected to processing pursuant to the right to
                    object and automated decision-making, pending the
                    verification whether the legitimate grounds for Zorbi
                    override those of you.
                  </p>
                  <h2>Portability</h2>
                  <p>
                    You may ask Zorbi to transfer the personal data you have
                    provided us to another organization and/or ask to receive
                    your personal data in a structured, commonly used and
                    machine readable format.
                  </p>
                  <p>
                    In case you provided your consent to the processing of
                    personal data, you may
                  </p>
                  <p>
                    withdraw consent at any time, without affecting the
                    lawfulness of processing based on consent before its
                    withdrawal.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    If your personal data are transferred outside the European
                    Economic Area, you have the right to obtain copy of such
                    data as well as indication of the Country/Countries
                  </p>
                  <p>where the personal data have been made available.</p>
                  <p>
                    <br />
                  </p>
                  <h2>
                    Your right to object to the processing of your personal data
                    <span class="p">
                      You have the right to object to the processing of your
                      personal data and request the stop of the processing
                      operations when they are based on the legitimate interest.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    Your right to lodge a complaint to the Supervisory Authority
                    <span class="p">
                      The criterion for the duration of the retention of
                      personal data is the respective legal retention period.
                      Once this period expires, the data in question will be
                      routinely erased, provided it is no longer required for
                      the fulfilment or initiation of the contract.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <p>
                    When we process your data we apply high-standard technical
                    and organizational measures in order to provide maximum
                    security as described above.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      For further information you can contact{" "}
                    </a>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="s1"
                      target="_blank"
                    >
                      contact@zorbiapp.com
                    </a>
                    <a href="mailto:contact@zorbiapp.com" target="_blank">
                      .
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>Transfer</h2>
                  <p>
                    The GDPR requires that when any EU user’s personal data is
                    hosted or processed outside of the European Economic Area,
                    it must remain protected to an adequate standard in line
                    with EU law. There are a few ways that Zorbi achieves this:
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    First, some of our EU customers’ data is processed in New
                    Zealand (where our
                  </p>
                  <p>
                    Headquarters are located). New Zealand is recognized by the
                    EU as a territory that ensures an adequate level of data
                    protection decided by 2013/65/EU: Commission Implementing
                    Decision of 19 December 2012 pursuant to Directive 95/46/EC
                    of the European Parliament and of the Council on the
                    adequate protection of personal data by New Zealand
                    (notified under document C(2012) 9557) Text with EEA
                    relevance.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    When we hold EU customer data in other territories, like the
                    US, we take other
                  </p>
                  <p>
                    ‘Appropriate Safeguards’ that are prescribed by the GDPR.
                    Specifically, we either transfer your data to countries
                    which are providing adequate protection according to EU or
                    we enter into Data Processing Agreements with Customers in
                    which we rely on EU Standard Contractual Clauses (also
                    called Model Clauses) published by the European Commission
                    to protect EU data. These are standard form data export
                    agreements that have been approved by the European
                    Commission as a lawful basis for transferring personal data
                    to non-EEA countries like the USA.
                  </p>
                  <p>
                    Secondly, When we hold EU customer data in other
                    territories, like the USA, we take other ‘Appropriate
                    Safeguards’ that are prescribed by the GDPR. Specifically,
                    we enter into Data Processing Agreements with Customers in
                    which we rely on EU
                  </p>
                  <p>
                    Standard Contractual Clauses (also called the SCCs or the EU
                    Model Clauses) published by the European Commission to
                    enforce the protection of EU users’ data. These are standard
                    form data export agreements that have been approved by the
                    European Commission as a lawful basis for transferring
                    personal data to non-EEA countries like the USA.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    (Zorbi wants to notify you that since July 16, 2020
                    according to a decision of the
                  </p>
                  <p>
                    Court of Justice of the European Union, the PrivacyShield
                    agreement governing the transfer of EU citizens’ data to the
                    United States is no longer applicable. As a result of that
                    the EU-U.S. PrivacyShield Framework is no longer a valid
                    mechanism to comply with EU data protection requirements
                    when transferring personal data from the European Union to
                    the United States. However as the regulatory and legislative
                    landscape evolves, we will always work to ensure that our
                    customers and partners can continue to enjoy the benefits of
                    our services and maximum protections everywhere they
                    operate. This is the reason why we still continue to comply
                    with the EU-U.S. Privacy Shield Framework as set forth by
                    the U.S. Department of
                  </p>
                  <p>
                    Commerce regarding the collection, use, and retention of
                    personal information transferred from the European Union to
                    the United States.)
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Thirdly, we have verified that our US-based cloud hosting
                    providers are working with us according to agreements which
                    include SCC (EU Model clauses) as explained above.
                    Specifically,
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    <a
                      href="https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/"
                      class="a"
                      target="_blank"
                    >
                      Amazon Web Services – our agreement with AWS incorporates
                      the EU SCCs for GDPR-compliant protection of EU users’
                      data as confirmed{" "}
                    </a>
                    <span>here</span>.
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    <a
                      href="https://cloud.google.com/terms/eu-model-contract-clause"
                      class="a"
                      target="_blank"
                    >
                      Google Cloud Platform – our agreement with GCP
                      incorporates the EU SCCs for GDPR-compliant protection of
                      EU users’ data as confirmed{" "}
                    </a>
                    <span>here</span>.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>
                    <a
                      href="https://stripe.com/dpa/legal"
                      class="a"
                      target="_blank"
                    >
                      Stripe – our agreement with Stripe incorporates the EU
                      SCCs for GDPR-compliant protection of EU users’ data{" "}
                    </a>
                    <a
                      href="https://stripe.com/dpa/legal"
                      class="s1"
                      target="_blank"
                    >
                      here
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Zorbi may not rely on a breach by a sub-processor of its
                    obligations in order to avoid its own liabilities.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">GDPR Rights of Arbitration</h1>
                  <p>
                    In case of a dispute or non-resolution related to privacy
                    issues, European users may invoke binding arbitration via
                    your EU data protection authority (DPAs).
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">United Kingdom Privacy Rights</h1>
                  <p>
                    We comply with the data privacy requirements under the UK
                    legislation. There are interim provisions which allow the
                    continued use of EU Standard Contractual
                  </p>
                  <p>Clauses (SCCs) for restricted transfers.</p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">California Privacy Rights</h1>
                  <p>
                    In addition to the rights as explained in this Policy,
                    California residents who provide Personal Information (as
                    defined in the statute) to obtain educational services are
                    entitled to request and obtain from us, once a calendar
                    year, information about the Personal Information we shared,
                    if any, with others third parties. If applicable, this
                  </p>
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      information would include the categories of Personal
                      Information and the names and addresses of those third
                      parties with which we shared such personal information for
                      the immediately prior calendar year (e.g., requests made
                      in the current year will receive information about the
                      prior year). For more information please contact us at:{" "}
                    </a>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="s1"
                      target="_blank"
                    >
                      contact@zorbiapp.com
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>DO NOT TRACK</h2>
                  <p>
                    Some browsers incorporate a Do Not Track feature that
                    signals to websites you visit that you do not want to have
                    your online activity tracked. Tracking is not the same as
                    using or collecting information in connection with a
                    website. For these purposes, tracking refers to collecting
                    personally identifiable information from consumers who use
                    or visit a website or online service as they move across
                    different websites over time. Our Website does not track its
                    visitors across third party websites. If your browser
                    indicates Do Not Track, our Website will not record your
                    visit.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">Australia privacy rights</h1>
                  <p>
                    If you reside in Australia, you have a legal right under the
                    Privacy Act 1988 (Cth) to request access to your personal
                    data which is held by Zorbi. You also have a right to seek
                    correction (including correction of any errors) of your
                    personal data. If you
                  </p>
                  <p>
                    would like to request access to, or correction of, your
                    personal data, please contact us. If you request access to,
                    or the correction of, your personal data, we will respond to
                    your request within a reasonable period after the request is
                    made. Access to your personal data will be provided in
                    accordance with any applicable laws and is subject to any
                    exemptions which might apply under those laws.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      For more information please contact us at:{" "}
                    </a>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="s1"
                      target="_blank"
                    >
                      contact@zorbiapp.com
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">Canada privacy rights</h1>
                  <p>
                    If you reside in Canada, you have a right to request access
                    to your personal data which is held by Zorbi and the right
                    to request its modification (including the
                  </p>
                  <p>
                    correction of any errors). If you would like to request
                    access to, or correction of, your
                  </p>
                  <p>
                    personal data, please contact us. We will respond to your
                    request within a reasonable period after the request is
                    made. To protect against fraudulent requests for access to
                    your personal data, we may ask you for additional
                    information to confirm that the person making the request is
                    you or is authorized to access your personal data. For
                    example, we may require you to verify your identity before
                    you access your personal data. Access to your personal data
                    will be provided in accordance with applicable laws and is
                    subject to any exemptions which might apply under those
                    laws.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="a"
                      target="_blank"
                    >
                      For more information please contact us at:{" "}
                    </a>
                    <a
                      href="mailto:contact@zorbiapp.com"
                      class="s1"
                      target="_blank"
                    >
                      contact@zorbiapp.com
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                  <h1 className="mt-2 mb-4">Disclosure and Permissions</h1>
                  <p>
                    We do not disclose personal information collected from users
                    to third parties other than to persons who provide support
                    for the operations of the service and who do not use the
                    information for any other purpose, as detailed above.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    We do not disclose personal information collected from
                    Children to third parties other than to persons who provide
                    support for the operations of the service and who do not use
                    the information for any other purpose, except as follows:
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li data-list-text="●">
                  <p>Parents</p>
                </li>
                <li data-list-text="●">
                  <p>Teachers &amp; school administrators</p>
                </li>
              </ul>
              <p>
                We may disclose your personally identifiable information in
                connection with business transfers and purchases: As we continue
                to develop our business we may buy or sell business divisions or
                companies, we may merge or combine with another company, or our
                company itself and/or all or a significant part of its assets
                may be acquired by another company. We may provide any
                information we have to a potential
              </p>
              <p>
                counter-party in any such potential transaction. If such a
                transaction is completed, your personally identifiable
                information may be one of the transferred and shared business
                assets. In the event that information is shared in this manner,
                notice will be posted on our Site. In this event this privacy
                policy will continue to apply unless we contact you and ask you
                to opt in to any changes before they are implemented.
              </p>
              <p>
                <br />
              </p>
              <p>
                We reserve the right to disclose your personally identifiable
                information as required by law and when we believe that
                disclosure is necessary to protect our rights and/or to comply
                with a judicial proceeding, court order, or legal process served
                on our Website.
              </p>
              <p>
                <br />
              </p>
              <h1 className="mt-2 mb-4">
                Cookies and similar technologies used
              </h1>
              <p>
                Technologies such as cookies, beacons, tags and scripts are used
                by Zorbi. These technologies are used to make it easier for you
                to navigate our site, to store your
              </p>
              <p>
                passwords so you don’t have to enter it more than once,
                analyzing trends, administering the site, tracking users’
                movements throughout the site and to gather usage information
                about our user base as a whole. We may receive reports based on
                the use of these technologies by these companies on an
                individual as well as aggregated basis.
              </p>
              <p>
                <br />
              </p>
              <p>
                As true of most Web Sites, we gather certain information
                automatically and store it in log files. This information may
                include internet protocol (IP) addresses, browser type, internet
                service provider (ISP), referring/exit pages, operating system,
                date/time stamp, and/or clickstream data. We do not link this
                automatically-collected data to other information we collect
                about you.
              </p>
              <p>
                <br />
              </p>
              <p>
                Zorbi collects and analyzes data on how the service is used in
                the aggregate for the purpose of improving and enhancing its
                service. Zorbi does not analyze information on how particular
                individuals use the service as part of general reporting
                procedures or in the usual course of business. However,
                information on individual usage of the system, including but not
                limited to individual IP addresses, may be analyzed on a
                case-specific basis to resolve a technical difficulty or to
                assist in resolving or
              </p>
              <p>investigating any misuse of the service.</p>
              <p>
                <br />
              </p>
              <h1 className="mt-2 mb-4">Changes to this Policy</h1>
              <p>
                We reserve the right to amend this Privacy Policy at any time.
                Please check this page periodically for changes. If we intend to
                make a material change in the collection, use, or disclosure
                practices with respect to a Child’s previously-collected
                personal information to which a school or district has
                previously has consented, we will contact by email to obtain the
                consent of the school, district, teacher or parent who
                registered for the service in connection with the child to such
                changes.
              </p>
              <p>
                <br />
              </p>
              <p>
                We also reserve the right to amend the EU Model Contract Clauses
                in case when the European Commission adopts new ones.
              </p>
              <p>
                <br />
              </p>
              <h1 className="mt-2 mb-4">Links to Other Sites</h1>
              <p>
                If you click on a link to a third-party site, you will leave the
                zorbiapp.com Website and be redirected to the site you selected.
                Because we cannot control the activities of third parties, we
                cannot accept responsibility for any use of your personally
              </p>
              <p>
                identifiable information by such third parties, and we cannot
                guarantee that they will adhere to the same privacy practices as
                Zorbi. We encourage you to review the privacy statements of any
                other service provider from whom you request services. If you
                visit a third-party website that is linked to the Zorbi Website,
                you should read that site’s privacy statement before providing
                any personally identifiable information with them.
              </p>
              <h1 className="mt-2 mb-4">Contact</h1>
              <p>
                <a href="mailto:contact@zorbiapp.com" class="a" target="_blank">
                  Have questions or concerns about Zorbi, our Services and
                  privacy? Contact us at{" "}
                </a>
                <a href="mailto:contact@zorbiapp.com" target="_blank">
                  contact@zorbiapp.com
                </a>
              </p>
              <p>Zorbi Limited [New Zealand]</p>
              <p>
                <br />
              </p>
              <p>20 Symonds Street, Auckland, New Zealand</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export default DeletePage;
